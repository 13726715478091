'use strict';

cloudTypeController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'CloudTypes'
];

function cloudTypeController($scope, $uibModal, $routeParams, $q, $location, CloudTypes) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      name: $scope.cloudType.cloudTypeName,
      instanceType: $scope.cloudType.cloudTypeInstanceType,
      status: $scope.cloudType.cloudTypeStatus
    };

    if ( $scope.iaasType ) {
      data.iaasTypeId = $scope.iaasType.iaasTypeId;
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var cloudTypeId = null;
    if ( !$scope.isNew ) {
      cloudTypeId = $scope.cloudType.cloudTypeId;
    }
    CloudTypes.save(cloudTypeId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'クラウドタイプレコードを保存しました。';
        $scope.cloudType = result;
        $scope.editMode = false;
        if ( $scope.isNew ) {
          $location.path('/cloudTypes/' + result.cloudTypeId);
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "クラウドタイプレコードの無効化";
        $scope.messageBody = "このクラウドタイプレコードを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 2
      };

      CloudTypes.save($scope.cloudType.cloudTypeId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'クラウドタイプレコードを無効にしました。';
          $scope.cloudType = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "クラウドタイプレコードの有効化";
        $scope.messageBody = "このクラウドタイプレコードを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 1
      };

      CloudTypes.save($scope.cloudType.cloudTypeId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'クラウドタイプレコードを有効にしました。';
          $scope.cloudType = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "クラウドタイプレコードの削除";
        $scope.messageBody = "この クラウドタイプレコードを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      CloudTypes.delete($scope.cloudType.cloudTypeId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'クラウドタイプレコードを削除しました。';
          $scope.cloudType = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and iaas account
  var _openIaasTypeModal = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_iaas_type.html",
      backdrop: "static",
      scope: $scope,
      controller: 'iaasTypeSelectorModalController',
      resolve: {
        params: function() {
          return {
            name: $scope.cloudType ? $scope.cloudType.cloudTypeName : null,
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.iaasType = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.delete = _delete;
  $scope.openIaasTypeModal = _openIaasTypeModal;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( $scope.isNew ) {
      _resetAction();
    }
    else {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      _loadCloudType()
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadCloudType = function() {
    return CloudTypes.getOne($scope.cloudTypeId).then(function(data) {
      if (data.message == undefined) {
        $scope.cloudType = data;
        $scope.iaasType = data.iaasType;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };

    $scope.action = null;
    $scope.editMode = false;
    if ($routeParams.id) {
      $scope.cloudTypeId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
    }
  };

  $scope.statuses = [
    { label: '削除済み', value: 0},
    { label: '有効', value: 1 },
    { label: '無効', value: 2}
  ];

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if ( $scope.cloudType) {
      if ( $scope.cloudType.cloudTypeStatus == 1 ) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canDisable = true;
        $scope.action.canDelete = true;
      }
      else if ( $scope.cloudType.cloudTypeStatus == 2 ) {
        $scope.action = {};
        $scope.action.canEnable = true;
        $scope.action.canDelete = true;
      }
    }
  };

  _initialize();
  _load();
};

module.exports = cloudTypeController;