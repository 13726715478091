'use strict';

Instances.$inject = [
  '$http',
  'API'
]

function Instances( $http, API ) {
  var _listInstances = function( pageNum, params ) {
    var url = API + '/api/instances/pages/' + pageNum;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _getInstance = function(id) {
    var url = API + '/api/instances/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, params) {
    var url = API + '/api/instances/' + id;
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _listAuthors = function(id) {
    var url = API + '/api/instances/' + id + '/mt/authors';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listSites = function(id) {
    var url = API + '/api/instances/' + id + '/mt/sites';
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    save: function(id, params) {
      return _save(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listInstances: function(pageNum, params) {
      return _listInstances(pageNum, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstance: function(id) {
      return _getInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listAuthors: function(id) {
      return _listAuthors(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listSites: function(id) {
      return _listSites(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = Instances;
