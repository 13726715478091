'use strict';

instanceController.$inject = [
  '$scope',
  '$routeParams',
  '$uibModal',
  '$q',
  'Instances'
]

function instanceController($scope, $routeParams, $uibModal, $q, Instances) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadInstance(),
      // _loadAuthors(),
      // _loadSites()
    ]).then(function(){
      modal.close();
    })
  };

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _loadInstance = function() {
    return Instances.getInstance($routeParams.id).then(function(data){
      $scope.instance = data;
      var initialFields = [ "Account", "Password", "Nickname", "Email" ];
      initialFields.forEach( function(field) {
        var f = "instanceInitialMtAdmin" + field;
        $scope[f] = $scope.instance.instanceInitial[f];
      });
      {
        const field = "Shadankun";
        var f = "instanceDetail" + field;
        $scope[f] = $scope.instance.instanceDetail[f];
      };
      {
        const field = "ShadankunStartDate";
        var f = "instanceDetail" + field;
        if ( $scope.instance.instanceDetail[f] ) {
          $scope[f] = new Date( $scope.instance.instanceDetail[f] );
        }
      };
    });
  }

  var _loadAuthors = function() {
    return Instances.listAuthors($routeParams.id).then(function(data){
      $scope.authors = data;
    });
  }

  var _loadSites = function() {
    return Instances.listSites($routeParams.id).then(function(data){
      $scope.sites = data;
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Open Product Search Modal Window
  var _searchProduct = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_product.html",
      backdrop: "static",
      scope: $scope,
      controller: 'productSearchController',
    });

    // Result
    modal.result.then(
      function(data) {
        console.log( data );
        var detail = $scope.instance.instanceDetail;
        detail.product = data;
        detail.instanceDetailPlan = data.productName;
        detail.instanceDetailProductVersion = data.productVersion;
        detail.instanceDetailMemory = data.productMetaDataMemory;
        detail.instanceDetailDist = data.productMetaDataDisk;
        detail.instanceDetailCpuNum = data.productMetaDataCpus;
        detail.instanceDetailServerType = data.productMetaDataWebserver;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  var _instanceDetailModal = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/instance_detail.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        _loadAuthors();
        _loadSites();
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }
    });
  }
  $scope.openInstanceDetailModal = _instanceDetailModal;

  ////////////////////////////////////////////////////////////
  // Operations
  ////////////////////////////////////////////////////////////
  var _edit = function() {
    $scope.editMode = true;
  }

  var _save = function() {
    var data = {
      instanceNote: $scope.instance.instanceNote,
      status: $scope.instance.instanceStatus,
      usage: $scope.instance.instanceUsage,
    };

    if ( $scope.instance.instanceDetail.product ) {
      data.instanceDetailProductId = $scope.instance.instanceDetail.product.productId;
    }

    var initialFields = [ "Account", "Password", "Nickname", "Email" ];
    initialFields.forEach( function(field) {
      var f = "instanceInitialMtAdmin" + field;
      if ( $scope[f] != $scope.instance.instanceInitial[f] ) {
        data[f] = $scope[f];
        $scope.instance.instanceInitial[f] = $scope[f];
      }
    });

    {
      const f = "instanceDetailShadankun";
      console.log( $scope[f]);
      console.log( $scope[f+"Checked"])
      var status = $scope[f+"Checked"] ? 1 : 0;
      console.log( status );
      if ( $scope.instance.instanceDetail[f] != status ) {
        data[f] = status;
        $scope.instance.instanceDetail[f] = status;
        $scope[f] = status;
      }
    }

    {
      const f = "instanceDetailShadankunStartDate";
      var date = $scope[f];
      console.log(date);

      function _ymd (dt) {
        var yyyy = dt.getFullYear();
        var mm = ('0' + (dt.getMonth() + 1)).slice(-2);
        var dd = ('0' + (dt.getDate())).slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      }

      if ( $scope.instance.instanceDetail[f] != date ) {
        if (date) {
          data[f] = _ymd(date);
          $scope.instance.instanceDetail[f] = $scope[f];
        } else {
          data[f] = null;
          $scope.instance.instanceDetail[f] = null;
        }
      }
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Instances.save($scope.instance.instanceId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'インスタンス情報を保存しました。';
        $scope.editMode = false;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
      $scope.editMode = false;
    })
  };

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.edit = _edit;
  $scope.save = _save;
  $scope.searchProduct = _searchProduct;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: ''
  };
  $scope.editMode = false;

  $scope.statuses = [
    { label: '削除済み', value: 0},
    { label: '稼働中', value: 1 },
    { label: '無効', value: 2},
    { label: '起動準備中', value: 3 },
    { label: '停止中', value: 4},
    { label: '削除対象', value: 5 },
    { label: '停止準備中', value: 6},
    { label: 'データ移行中', value: 7 },
    { label: '削除中', value: 8},
    { label: '再起動中', value: 9 },
    { label: 'プラン変更中', value: 10 },
    { label: '削除実行待ち', value: 11 }
  ];

  $scope.usages = [
    { label: '無償提供', value: 0},
    { label: '通常提供', value: 1 },
    { label: 'トライアル', value: 2},
    { label: 'サンドボックス', value: 3 },
    { label: '検証用', value: 4},
    { label: '社内利用', value: 99 },
  ];

  _load();
};

module.exports = instanceController;
