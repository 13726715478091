'use strict';

ansibleLogController.$inject = [
  '$scope',
  '$uibModal',
  'ansibleLogs'
];

function ansibleLogController($scope, $uibModal, ansibleLogs) {

  //////////////////////////////////////////////////////////////////////
  // Funcions
  //////////////////////////////////////////////////////////////////////
  var _reload = function() {
    _load();
  };

  //////////////////////////////////////////////////////////////////////
  // Loading log data
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    ansibleLogs.getLog($scope.target).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.log = result.log;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Funcions Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.load = _load;
  $scope.reload = _reload;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };

    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth();
    if ( month == 0 ) {
      month = 12;
    }
    var lastMonth = ( '00' + year ).slice(-4) + ( '00' + month ).slice(-2) + '01';

    $scope.targets = [
      { label: '今月', value: 'current'},
      { label: '先月', value: lastMonth }
    ];

  };

  _initialize();
};

module.exports = ansibleLogController;
