'use strict';

accountController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$localStorage',
  'Users'
];

function accountController($scope, $uibModal, $routeParams, $localStorage, Users) {

  //////////////////////////////////////////////////////////////////////
  // Save accoumt record
  var _save = function() {
    var data = {
      displayName: $scope.account.accountDisplayName,
      email: $scope.account.accountEmail,
      username: $scope.account.accountUsername,
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Users.save($scope.accountId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'アカウント情報を更新しました。';
        $scope.editMode = false;
        if ( $scope.account.accountId == $localStorage.user.accountId) {
          $localStorage.user = result.data;
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    })
  };

  //////////////////////////////////////////////////////////////////////
  // Delete account
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "アカウントの削除";
        $scope.messageBody = "このアカウントを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Users.delete($scope.accountId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "アカウントを削除しました。";
          $scope.account = data;
          _resetAction(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Disable account
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "アカウントの無効化";
        $scope.messageBody = "このアカウントを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Users.disable($scope.accountId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "アカウントを無効にしました。";
          $scope.account = data;
          _resetAction(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable account
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "アカウントの有効化";
        $scope.messageBody = "このアカウントを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Users.enable($scope.accountId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "アカウントを有効にしました。";
          $scope.account = data;
          _resetAction(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Open Maillog
  var _openMailLog = function() {
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/mail_detail.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope){
        $scope.mailLog = $scope.mailLog;
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Reset password
  var _resetPassword = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "仮パスワードの発行";
        $scope.messageBody = "このアカウントに新しい仮パスワードを発行します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do reset password
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Users.resetPassword($scope.accountId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          if ( data.accountUsername.indexOf('@') > 0 ) {
            $scope.messages.message = "仮パスワードを「" + data.accountUsername + "」宛に送信しました。";
          }
          else {
            $scope.messages.message = "仮パスワードを「" + data.accountEmail + "」宛に送信しました。";
          }
          $scope.account = data;
          _resetAction(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor
  var _addVendor = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_vendor.html",
      backdrop: "static",
      scope: $scope,
      controller: 'vendorSelectorController',
      resolve: {
        params: function() {
          return {
            accountName: $scope.account.accountDisplayName,
            vendors: $scope.vendors
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        if ( data && data.length > 0 ) {
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          var params = {
            vendorIds: data
          };

          Users.addVendors($scope.account.accountId, params).then(function(res){
            if (res.message == undefined) {
              $scope.vendors = res;
              $scope.messages.status = 'success';
              $scope.messages.message = "ベンダーを追加しました。";
            }
            else {
              $scope.messages.status = 'danger';
              $scope.messages.message = data.message;
            }
            modal.close();
          });
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Remove relation with vendor
  var _removeVendor = function(idx) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var vendor = $scope.vendors[idx];
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "ベンダーとの関連を削除";
        $scope.messageBody = "このアカウントと" + vendor.vendorName + "の関連を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Users.removeVendor($scope.accountId, vendor.vendorId).then(function(data) {
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = vendor.vendorName + "との関連を削除しました。";
          $scope.vendors = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        modal.close();
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for instance
  var _addInstance = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_instance.html",
      backdrop: "static",
      scope: $scope,
      controller: 'instanceSelectorController',
      resolve: {
        params: function() {
          return {
            accountName: $scope.account.accountDisplayName,
            instances: $scope.instances
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        if ( data && data.length > 0 ) {
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          var params = {
            instanceIds: data
          };

          Users.addInstances($scope.account.accountId, params).then(function(res){
            if (res.message == undefined) {
              $scope.instances = res;
              $scope.messages.status = 'success';
              $scope.messages.message = "インスタンスを追加しました。";
            }
            else {
              $scope.messages.status = 'danger';
              $scope.messages.message = data.message;
            }
            modal.close();
          });
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Remove relation with instance
  var _removeInstance = function(idx) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var instance = $scope.instances[idx];
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "インスタンスとの関連を削除";
        $scope.messageBody = "このアカウントと" + instance.instanceHostname + "の関連を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Users.removeInstance($scope.accountId, instance.instanceId).then(function(data) {
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = instance.instanceHostname + "との関連を削除しました。";
          $scope.instances = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        modal.close();
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change customer and customer person
  var _openCustomerAndPersonSelector = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer_and_person.html",
      backdrop: "static",
      scope: $scope,
      controller: 'customerAndPersonSelectorModalController',
    });

    // Result
    modal.result.then(
      function(data) {
        if ( data ) {
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          var customer = data.customer;
          var customerPerson = data.customerPerson;
          var params = {
            customerId: data.customer.customerId,
            customerPersonId: data.customerPerson.customerPersonId
          };

          Users.changeCustomer($scope.account.accountId, params).then(function(res){
            if (res.message == undefined) {
              $scope.customer = customer;
              $scope.customerPerson = customerPerson;
              $scope.messages.status = 'success';
              $scope.messages.message = "新しい顧客と関連付けました。";
            }
            else {
              $scope.messages.status = 'danger';
              $scope.messages.message = data.message;
            }
            modal.close();
          });
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Utilities
  //////////////////////////////////////////////////////////////////////

  // Load Maillog
  var _loadMailLog = function(id) {
    MailLog.listMailLogByAccount(id).then(function(result){
      if (result.message == undefined) {
        if (result.length > 0) {
          $scope.mailLog = result[result.length - 1];
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  // Load Instances
  var _loadInstances = function(id) {
    Users.getInstances(id).then(function(result){
      if (result.message == undefined) {
        if (result.length > 0) {
          $scope.instances = result;
        }
        $scope.isLoadingInstances = false;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  // Load Vendors
  var _loadVendors = function(id) {
    Users.getVendors(id).then(function(result){
      if (result.message == undefined) {
        if (result.length > 0) {
          $scope.vendors = result;
        }
        $scope.isLoadingVendors = false;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  // Load Customer
  var _loadCustomer = function(id) {
    Users.getCustomer(id).then(function(result){
      if ( result ) {
        if (result.message == undefined) {
          $scope.customer = result;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      }
    });
  };

  // Load Customer
  var _loadCustomerPerson = function(id) {
    Users.getCustomerPerson(id).then(function(result){
      if ( result ) {
        if (result.message == undefined) {
          $scope.customerPerson = result;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      }
    });
  };


  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function(data) {
    $scope.action = null;

    if (data.accountStatus == 0) {
      // Deleted
    } else if (data.accountStatus == 1) {
      // Enabled
      $scope.action = {};
      $scope.action.canEdit = true;
      if ( $scope.account.accountId == $localStorage.user.accountId) {
        $scope.action.canDisable = false;
        $scope.action.canDelete = false;
        $scope.action.canEditUsername = false;
      }
      else {
        $scope.action.canDisable = true;
        $scope.action.canDelete = true;
        $scope.action.canEditUsername = true;
      }
      $scope.action.canEnable = false;
      $scope.action.canResetPassword = true;
    } else if (data.accountStatus == 2) {
      // Disabled
      $scope.action = {};
      $scope.action.available = true;
      $scope.action.canEdit = false;
      $scope.action.canDisable = false;
      $scope.action.canEnable = true;
      $scope.action.canDelete = true;
      $scope.action.canResetPassword = false;
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading account
  var _loadAccount = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    $scope.accountId = $routeParams.id;
      Users.getUser($scope.accountId).then(function(data) {
      modal.close();
      if (data.message == undefined) {
        $scope.account = data;
        _resetAction(data);
//        _loadMailLog($scope.accountId);
        _loadInstances($scope.accountId);
        _loadCustomerPerson($scope.accountId);
        _loadCustomer($scope.accountId);

        if ( data.accountPrivileges.indexOf("ROLE_VENDOR") || data.accountPrivileges.indexOf("ROLE_ADMIN") || data.accountPrivileges.indexOf("ROLE_SIXAPART") ) {
          _loadVendors($scope.accountId);
          $scope.canManageVendor = true;
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.delete = _delete;
  $scope.resetPassword = _resetPassword;
  $scope.addVendor = _addVendor;
  $scope.removeVendor = _removeVendor;
  $scope.addInstance = _addInstance;
  $scope.removeInstance = _removeInstance;
  $scope.openCustomerAndPersonSelector = _openCustomerAndPersonSelector;

  //////////////////////////////////////////////////////////////////////
  // Initialization
  //////////////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };
  $scope.terms = [{
      label: '月額',
      value: 1
    },
    {
      label: '年額',
      value: 3
    }
  ];

  $scope.action = null;
  $scope.editMode = false;
  $scope.mailLog = null;
  $scope.instances = null;
  $scope.canManageVendor = false;
  $scope.isLoadingInstances = true;
  $scope.isLoadingVendors = true;

  _loadAccount();
};

module.exports = accountController;