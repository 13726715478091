'use strict';

addOptionModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'CloudOptions',
  'OptionServices',
  'params'
];

function addOptionModalController($uibModalInstance, $scope, $uibModal, CloudOptions, OptionServices, params) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  // OK
  var _save = function() {
    var data = {
      cloudServiceId: $scope.cloudOption.cloudOptionId,
      status: 1,
    };

    var yyyy = $scope.startDate.getFullYear();
    var mm = ('0' + ($scope.startDate.getMonth() + 1)).slice(-2);
    var dd = ('0' + ($scope.startDate.getDate())).slice(-2)
    data.startDate = yyyy + '-' + mm + '-' + dd + ' 00:00:00';

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    OptionServices.save($scope.orderId , data).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $uibModalInstance.close(data);
      } else {
        $uibModalInstance.dismiss(data.message);
      }
    });
  };

  // Cancel
  var _cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  ////////////////////////////////////////////////////////////
  // Function Mappings
  ////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.cancel = _cancel;

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _listCloudOptions = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    CloudOptions.listCloudOptions().then(
      function(data){
        if (data && data.length > 0) {
          $scope.cloudOptions = data;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.orderId = params.orderId;

  $scope.messages = {
    status: '',
    message: '',
  };

  _listCloudOptions();

};

module.exports = addOptionModalController;
